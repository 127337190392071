/* latin-ext */
@font-face {
  font-family: 'Noto Sans Mono';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: local('Noto Sans Mono'), url('./fonts/NotoSansMono-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Noto Sans Mono';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: local('Noto Sans Mono'), url('./fonts/NotoSansMono-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* For app title only */
@font-face {
  font-family: 'Sixtyfour';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Sixtyfour'), url('./fonts/Sixtyfour-Cryptex-title-only.woff2') format('woff2');
}

/* For app title only */
@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Noto Sans SC'), url('./fonts/NotoSansSC-zh_cn-title-only.woff2')  format('woff2');
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111; /* dark background */
  color: #0ff; /* bright cyan text */
}

code {
  font-family: "Noto Sans Mono", Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: #f0f; /* bright magenta text */
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;
  background-color: #111; /* dark background */
}
