.App {
  text-align: center;
  padding: 20px;
  margin: 2%;
  background-color: #222; /* darker background for contrast */
  border-radius: 10px;
  box-shadow: 0px 0px 10px #0ff; /* bright cyan shadow */
}

.App-header {
  margin-bottom: 20px;
  text-shadow: 4px 4px 4px #0ff; /* bright cyan shadow */
  font-family: 'Sixtyfour', 'Noto Sans SC', sans-serif;
  overflow-wrap: break-word;
}

.error {
  color: #f00; /* bright red text for errors */
}

/* #encryptDecryptForm {
  display: flex;
  flex-direction: column;
} */

input, textarea {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 2px solid #0ff; /* bright cyan border */
  background-color: #222; /* darker background for contrast */
  color: #0ff; /* bright cyan text */
}

textarea {
  resize: vertical;
}

button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
  background-color: #222; /* darker background for contrast */
  color: #0ff; /* bright cyan text */
  border: 2px solid #0ff; /* bright cyan border */
}

button:hover {
  background-color: #0ff; /* bright cyan background on hover */
  color: #000; /* black text on hover */
}

button:disabled {
  cursor: not-allowed;
  background-color: #555; /* grey background for disabled buttons */
  color: #aaa; /* grey text for disabled buttons */
}

p, pre {
  word-break: break-all;
  text-wrap: pretty;
  white-space: pre-wrap;
}

pre {
  font-family: "Noto Sans Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  color: #0f0; /* bright green text for preformatted text */
  text-align: justify;
}

hr {
  width: auto;
  border: none;
  height: 2px;
  background-image: linear-gradient(90deg, #39ff14, #76ff03, #39ff14);
  box-shadow: 0px 2px 10px #0ff, 0px -2px 10px #0ff;
  margin: 1.2rem 0; /* Adjust the margin as needed */
}
